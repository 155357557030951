<template>
  <div v-if="isManager">
    <div style="display: flex; flex-direction: row; margin-top: 17px; margin-bottom: 22px">
      <v-text-field
        v-model="search"
        label="Начните наименование журнала..."
        style="max-width: 400px" />
      <button class="usual-btn usual-btn_bold" style="margin: 0 0 0 auto" @click="clickOnRow1">
        Добавление журнала
      </button>
    </div>
    <GridTable
      :data-rows="showingServerData"
      :headers="tableHeaders.orgMassive"
      :headers-for-rows="tableHeaders.orgMassive"
      :num-cols="tableHeaders.countCells"
      :loading-data="loading"
      :borderTop="'none'"
      style="background: #ffffff; box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06); border-radius: 4px">
      <!--      <template #num="{ row }">-->
      <!--        <span>{{ row.rowOrder }}</span>-->
      <!--      </template>-->
      <!--      <template #id="{ row }">-->
      <!--        <span>{{ row.id }}</span>-->
      <!--      </template>-->
      <!--      <template #issn="{ row }">-->
      <!--        <span>{{ row.issn }}</span>-->
      <!--      </template>-->
      <!--      <template #eissn="{ row }">-->
      <!--        <span>{{ row.eissn }}</span>-->
      <!--      </template>-->
      <!--      <template #name="{ row }">-->
      <!--        <span>{{ row.name }}</span>-->
      <!--      </template>-->
      <!--      <template #country="{ row }">-->
      <!--        <span>{{ row.country }}</span>-->
      <!--      </template>-->
      <template #rinc="{ row }">
        <span v-if="row.rinc === 1">Да</span>
        <span v-else>{{ row.rinc }}</span>
      </template>
      <template #rincCore="{ row }">
        <span v-if="row.rincCore === 1">Да</span>
        <span v-else>Нет</span>
      </template>
      <template #vak="{ row }">
        <span v-if="row.vak === 1">Да</span>
        <span v-else>Нет</span>
      </template>
      <template #wosCc="{ row }">
        <span v-if="row.wosCc === 1">Да</span>
        <span v-else>Нет</span>
      </template>
      <!--      <template #sjrQuartile="{ row }">-->
      <!--        <span>{{ row.sjrQuartile }}</span>-->
      <!--      </template>-->
      <template #scopus="{ row }">
        <span v-if="row.scopus === 1">Да</span>
        <span v-else>Нет</span>
      </template>
      <!--      <template #jcrQuartile="{ row }">-->
      <!--        <span>{{ row.jcrQuartile }}</span>-->
      <!--      </template>-->
      <template #actions="{ row }">
        <img
          src="@/assets/icons/edit-btn.svg"
          style="margin-right: 15px"
          alt="edit"
          @click="clickOnRow2(row)" />
        <div class="applicant__delete" @click="deleteConfirmFunc(row.id)"></div>
      </template>
    </GridTable>
    <div class="mt-3 d-flex flex-row justify-center align-center">
      <v-autocomplete
        class="pagination-table mr-5"
        v-model="userPages"
        :items="pagesPaginate"
        hide-details
        solo
        dense
        filled
        height="25"
        rounded
        style="max-width: 150px"
        @change="changePage($event)"></v-autocomplete>
      <v-pagination
        v-model="page"
        :length="pagesVuetify"
        :total-visible="7"
        @input="changePage($event)">
      </v-pagination>
    </div>
    <div v-if="journalDialog">
      <div class="zxczxc__overlay"></div>
      <div class="zxczxc">
        <v-card class="dialog">
          <img
            @click="journalDialog = false"
            class="dialog__exit"
            src="@/assets/icons/dialog-exit.png"
            alt="exit" />
          <h2 class="dialog__header">Добавление/изменение журнала</h2>
          <div class="dialog__line"></div>
          <div class="dialog__div">
            <label class="dialog__label">Наименование журнала</label>
            <v-text-field v-model="journalObject.name" required></v-text-field>
            <label class="dialog__label">Страна</label>
            <v-text-field v-model="journalObject.country" required></v-text-field>
            <label class="dialog__label">ИССН</label>
            <v-text-field v-model="journalObject.issn" required></v-text-field>
            <label class="dialog__label">Электронный ИССН</label>
            <v-text-field v-model="journalObject.eissn" required></v-text-field>
            <div style="display: flex; flex-direction: row">
              <div class="dialog__type-input">
                <label class="dialog__label">Входит в РИНЦ</label>
                <v-autocomplete
                  v-model="journalObject.rinc"
                  :items="autocompleteOptions"></v-autocomplete>
              </div>
              <div class="dialog__type-input">
                <label class="dialog__label">Входит в ядро РИНЦ</label>
                <v-autocomplete
                  v-model="journalObject.rincCore"
                  :items="autocompleteOptions"></v-autocomplete>
              </div>
              <div class="dialog__type-input">
                <label class="dialog__label">Входит в ВАК</label>
                <v-autocomplete
                  v-model="journalObject.vak"
                  :items="autocompleteOptions"></v-autocomplete>
              </div>
              <div class="dialog__type-input">
                <label class="dialog__label">Входит в Web Of Science</label>
                <v-autocomplete
                  v-model="journalObject.wosCc"
                  :items="autocompleteOptions"></v-autocomplete>
              </div>
              <div class="dialog__type-input" style="margin-right: 0">
                <label class="dialog__label">Входит в Scopus</label>
                <v-autocomplete
                  v-model="journalObject.scopus"
                  :items="autocompleteOptions"></v-autocomplete>
              </div>
            </div>
            <label class="dialog__label">Квартиль в Web Of Science</label>
            <v-text-field v-model="journalObject.sjrQuartile" required></v-text-field>
            <label class="dialog__label">Квартиль в Scopus</label>
            <v-text-field v-model="journalObject.jcrQuartile" required></v-text-field>
          </div>
          <div class="dialog__line"></div>
          <v-card-actions>
            <button class="dialog-btn" @click="updateJournal">Сохранить</button>
          </v-card-actions>
        </v-card>
      </div>
    </div>
    <v-dialog v-model="deleteConfirmDialog" width="375">
      <v-card>
        <v-card-title>Подтверждение удаления строки</v-card-title>
        <v-card-actions style="justify-content: center">
          <v-btn class="dialog-btn" @click="deleteRow">Да</v-btn>
          <v-btn class="dialog-btn" @click="deleteConfirmDialog = false">Нет</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'; //mapActions
  import { GridTable } from '@frontenddevelopers/ined-components/src/lib';
  import JournalEditingJson from '@/2024/components/applicants/admin/journals/JournalEditingJson';
  import axios from 'axios';
  import { tablePagination } from '@/2024/components/applicants/admin/journals/tablePagination';

  export default {
    name: 'JournalEditing',
    components: { GridTable },
    mixins: [tablePagination],
    data() {
      return {
        search: null,
        maxPages: null,
        journalDialog: false,
        deleteConfirmDialog: false,
        deleteRowID: null,
        journalArray: [],
        tableHeaders: [],
        autocompleteOptions: [
          { text: 'Да', value: 1 },
          { text: 'Нет', value: 0 },
        ],
        journalObject: {
          country: null,
          eissn: null,
          issn: null,
          jcrQuartile: null,
          name: null,
          rinc: null,
          rincCore: null,
          scopus: null,
          sjrQuartile: null,
          vak: null,
          wosCc: null,
        },
      };
    },
    computed: {
      ...mapGetters('keycloak', {
        getAuthData: 'getAuthData',
        // getFakeUser: 'getFakeUser',
        isManager: 'isManager',
        getOrgsStatus: 'getOrgsStatus',
      }),
      // serverData() {
      //   return this.journalArray
      // },
      // serverData() {
      //   return this.journalArray.filter(
      //     (el) => !this.search || el.name.toLowerCase().search(this.search.toLowerCase()) !== -1,
      //   );
      // },
      showingData() {
        return this.journalArray.filter(
          (el) => !this.search || el.name.toLowerCase().search(this.search.toLowerCase()) !== -1,
        );
      },
      loading() {
        return this.getOrgsStatus;
      },
    },
    created() {
      this.getSprJournal();
      this.tableHeaders = JournalEditingJson(this);
    },
    methods: {
      async getSprJournal() {
        this.journalArray = [];
        try {
          const res = await axios.get(`/api/2024/spr/eLibrary/getSpr?pageNumber=0&pageSize=100`, {
            headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
          });
          if (res.status === 200 && res.data) {
            this.maxPages = res.data.maxPages;
            await this.getSprJournal2();
            console.log('getSprJournal успех');
          }
        } catch (e) {
          console.log(e);
        }
      },

      async getSprJournal2() {
        try {
          const res = await axios.get(
            `/api/2024/spr/eLibrary/getSpr?pageNumber=0&pageSize=${100 * this.maxPages}`,
            { headers: { Authorization: `Bearer   ${this.getAuthData.token}` } },
          );
          if (res.status === 200 && res.data) {
            this.journalArray = res.data.data;
            for (let a = 0; a < this.journalArray.length; a++) {
              this.journalArray[a].rowOrder = a + 1;
            }
            console.log('getSprJournal2 успех');
          }
        } catch (e) {
          console.log(e);
        }
      },

      clickOnRow1() {
        this.journalObject = {
          country: null,
          eissn: null,
          issn: null,
          jcrQuartile: null,
          name: null,
          rinc: null,
          rincCore: null,
          scopus: null,
          sjrQuartile: null,
          vak: null,
          wosCc: null,
        };
        this.journalDialog = true;
      },

      clickOnRow2(row) {
        this.journalObject = row;
        this.journalDialog = true;
      },

      async updateJournal() {
        try {
          const res = await axios.post(
            `/api/2024/spr/eLibrary/update`,
            {
              id: this.journalObject.id ? this.journalObject.id : null,
              issn: this.journalObject.issn !== null ? this.journalObject.issn : '',
              eissn: this.journalObject.eissn !== null ? this.journalObject.eissn : '',
              name: this.journalObject.name !== null ? this.journalObject.name : '',
              country: this.journalObject.country !== null ? this.journalObject.country : '',
              rinc: this.journalObject.rinc !== null ? this.journalObject.rinc : 0,
              rincCore: this.journalObject.rincCore !== null ? this.journalObject.rincCore : 0,
              vak: this.journalObject.vak !== null ? this.journalObject.vak : 0,
              wosCc: this.journalObject.wosCc !== null ? this.journalObject.wosCc : 0,
              jcrQuartile:
                this.journalObject.jcrQuartile !== null ? this.journalObject.jcrQuartile : 0,
              scopus: this.journalObject.scopus !== null ? this.journalObject.scopus : 0,
              sjrQuartile:
                this.journalObject.sjrQuartile !== null ? this.journalObject.sjrQuartile : 0,
            },
            { headers: { Authorization: `Bearer   ${this.getAuthData.token}` } },
          );
          if (res.status === 200 && res.data) {
            await this.getSprJournal();
            this.journalDialog = false;
            console.log('updateJournal успех');
          }
        } catch (e) {
          console.log(e);
        }
      },

      deleteConfirmFunc(rowID) {
        this.deleteConfirmDialog = true;
        this.deleteRowID = rowID;
      },

      async deleteRow() {
        try {
          const res = await axios.delete(
            `/api/2024/spr/eLibrary/delete?journalId=${this.deleteRowID}`,
            { headers: { Authorization: `Bearer   ${this.getAuthData.token}` } },
          );
          if (res.status === 200 && res.data) {
            console.log('deleteRow успех');
            await this.getSprJournal();
            this.deleteRowID = null;
            this.deleteConfirmDialog = false;
          }
        } catch (e) {
          console.log(e);
        }
      },
    },
  };
</script>

<style scoped></style>
